<template>
    <div class="contact-page page-font">
      <!-- Banner -->
      <div class="banner">
        <Banner :bannerList="bannerList"
                :scale="0.5"
                :opacity="0.3"
                position="center"/>
        <VisibleBox direction="bottom" style="position: relative; z-index: 2">
          <h2>Contact</h2>
        </VisibleBox>
      </div>

      <!-- 联系 -->
      <div class="contact main" style="padding: 50px 0 80px 0;">
        <!-- <ContactUs/> -->
        <div class="contact-info">
          <VisibleBox>
            <!-- <p class="title">Contact Information</p> -->
            <div class="info-items">
              <div class="info-item" v-if="baseInfo.phone">
                <span class="icon"><i class="custom-icon2-phone"></i></span>
                <span>{{baseInfo.phone}}</span>
              </div>
              <div class="info-item" v-if="baseInfo.email">
                <span class="icon"><i class="custom-icon2-email"></i></span>
                <span>{{baseInfo.email}}</span>
              </div>
              <a class="info-item" target="_blank" :href="`http://wpa.qq.com/msgrd?v=3&uin=${baseInfo.qq}&site=qq&menu=yes`" v-if="baseInfo.qq">
                <span class="icon"><i class="custom-icon2-qq"></i></span>
                <span>{{baseInfo.qq}}</span>
              </a>
              <div class="info-item" v-if="baseInfo.wechat">
                <span class="icon"><i class="custom-icon2-wechat"></i></span>
                <span>{{baseInfo.wechat}}</span>
              </div>
              <div class="info-item" v-if="baseInfo.contact">
                <span class="icon"><i class="custom-icon2-skype"></i></span>
                <span>{{baseInfo.contact}}</span>
              </div>
              <div class="info-item" v-if="baseInfo.address">
                <span class="icon"><i class="custom-icon2-address"></i></span>
                <span>{{baseInfo.address}}</span>
              </div>
            </div>
          </VisibleBox>
        </div>
        <div class="contact-form">
          <VisibleBox>
            <p class="title">Contact Us</p>
            <div class="form">
              <div class="form-item">
                <input type="text" v-model="form.title" class="input" placeholder="Title" />
              </div>
              <div class="form-item">
                <input type="text" v-model="form.contact" class="input" placeholder="Your Email / Phone" />
              </div>
              <div class="form-item">
                <textarea v-model="form.content" class="input textarea" placeholder="Your Message" />
              </div>
              <div class="error" v-if="showError">
                Please fill in the all fields
              </div>
              <div class="footer">
                <div class="btn-submit" @click="onCreate">{{sending ? 'Sending...' : 'Send a Request'}}</div>
              </div>
            </div>
          </VisibleBox>
        </div>
      </div>
    </div>
</template>

<script>
import Banner from '../components/Banner.vue'
import VisibleBox from '../components/VisibleBox.vue'
// import ContactUs from '../components/ContactUs.vue'
import { createMessage } from '../api/api'

export default {
  name: 'Contact',
  inject: ['getBaseInfo'],
  components: {
    Banner,
    VisibleBox
    // ContactUs
  },
  props: {
    
  },
  computed:{
    baseInfo(){
      return this.getBaseInfo()
    }
  },
  data(){
    return {
      bannerList: [
        {imgUrl: require(`../assets/contact/contact-banner-01.jpg`)}
      ],
      form: {
        title: '',
        contact: '',
        content: ''
      },
      showError: false,
      sending: false
    }
  },
  methods: {
    async onCreate(){
      try{
        const isValid = this.form.title && this.form.contact && this.form.content
        if(this.sending){
          return 
        }
        if(!isValid){
          this.showError = true
          return 
        }
        this.showError = false
        this.sending = true
        await createMessage(this.form)
        this.form = {
          title: '',
          contact: '',
          content: ''
        }
      }finally{
        this.sending = false
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '../styles/variables.scss';

.contact-page{
  .title{
    font-size: 26px;
    margin-bottom: 40px;
    font-weight: 700;
    text-align: center;
  }
  .contact{
    // display: flex;
    .contact-info{
      padding-top: 30px;
      .info-items{
        display: flex;
        flex-wrap: wrap;
      }
      .info-item{
        width: 33%;
        display: flex;
        flex-direction: column;
        align-items: center;
        // color: rgba(#666666, .8);
        font-size: 16px;
        margin-bottom: 80px;
      }
      .icon{
        display: block;
        height: 50px;
        margin-bottom: 10px;
        i{
          color: $primary-color;
          font-size: 36px;
          &.custom-icon2-phone{
            font-size: 38px;
          }
          &.custom-icon2-email{
            font-size: 42px;
          }
          &.custom-icon2-qq{
            font-size: 34px;
          }
          &.custom-icon2-skype{
            font-size: 38px;
          }
        }
      }
    }
    .contact-form{
      // flex: 1;
      margin-top: 50px;
      .form{
        width: 80%;
        margin: 0 auto;
      }
      input, textarea{
        font-size: 18px!important;
      }
    }
  }
}
</style>
